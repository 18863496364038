.privacy-content {
  font-size: 1rem;
  color: #4a4a4a;
}

.privacy-content h2 {
  margin-top: 2rem;
  margin-bottom: 1rem;
  font-weight: bold;
}

.privacy-content h3 {
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  font-weight: bold;
}

.privacy-content p,
.privacy-content ul,
.privacy-content ol {
  margin-bottom: 1rem;
}

.privacy-content ul,
.privacy-content ol {
  padding-left: 1.5rem;
}

.privacy-content a {
  color: #007bff;
  text-decoration: none;
}

.privacy-content a:hover {
  text-decoration: underline;
}
