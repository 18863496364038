.ticker {
  overflow: hidden;
  padding: 10px 0;
  background: linear-gradient(
    to right,
    rgb(0, 74, 173) 0%,
    rgb(0, 130, 223) 100%
  );
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 24px;
  font-weight: bold;
}

.ticker-container {
  display: flex;
  align-items: center;
  animation: ticker 30s linear infinite;
}

.ticker-item {
  display: flex;
  align-items: center;
  margin-right: 80px;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
  border-radius: 10px;
}

.ticker-image {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 5px;
}

.ticker-title {
  font-weight: bold;
  margin-left: 10px;
  margin-right: 5px;
}

.ticker-item-link {
  text-decoration: none;
  color: inherit;
}

.ticker-item-link:hover .ticker-item {
  background-color: #717fe7;
}

.ticker-change {
  font-weight: bold;
}

.ticker-change.positive {
  color: #90ee90;
}

.ticker-change.negative {
  color: rgb(248, 27, 27);
}

@keyframes ticker {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
