body {
  font-family: "Arial", sans-serif;
  background-color: #f4f4f9;
  margin: 0;
  padding: 0;
}

.home-container {
  margin: 0 auto;
  padding: 20px;
}

.home-header {
  text-align: center;
  margin-bottom: 40px;
}

.home-header h1 {
  font-size: 3rem;
  color: #333;
}

.home-header p {
  font-size: 1.2rem;
  color: #666;
}

.search-sort-filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.search-bar {
  flex: 1;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-right: 10px;
}

.sort-dropdown,
.filter-dropdown {
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.meme-card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
}

.meme-card:hover {
  transform: translateY(-5px);
}

.meme-image {
  width: 100%;
  height: auto;
  max-height: 200px; /* Adjust this value based on your design */
  object-fit: cover; /* Ensures the image covers the container without distortion */
  border-radius: 8px; /* Rounded corners for a softer look */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  margin-bottom: 10px; /* Space between the image and the text */
}

.social-icons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.social-icons a {
  color: #333;
  transition: color 0.3s;
}

.social-icons a:hover {
  color: #007bff; /* Change to your desired hover color */
}
